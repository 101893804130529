<template>
	<div class="step2-container">
		<CategoryCard
			v-for="category in listCategories"
			:key="category._id"
			:category="category"
			:formationId="formationId"
			:step="2"
			:hideRuleTags="true"
			class="mb-4"
			@selectionSucceed="selectionSucceed"
			@categorySelected="categorySelected"
		/>
	</div>
</template>

<script>
import CategoryCard from '@/components/reports/category/CategoryCard.vue';

export default {
	name: 'ChooseChapterStep2',
	components: { CategoryCard },
	props: {
		listCategories: Array,
		formationId: String,
	},
	methods: {
		selectionSucceed(value) {
			this.$emit('selectionSucceed', value);
		},
		categorySelected(value) {
			this.$emit('categorySelected', value);
		},
	},
};
</script>

<style lang="scss" scoped>
.step2-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.card {
	padding: 2rem;
	display: flex;
	gap: 10px;
	box-shadow: none !important;
	border-radius: 10px;
}

.cardInfo {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.title {
		font-weight: bold;
	}
	.actionBtn {
		max-width: 150px;
		text-transform: capitalize;
	}

	.lockedSession {
		display: flex;
		align-items: center;
	}
}

.cardThumbnail {
	width: 20%;
	display: flex;
	align-items: center;
	.thumbnail {
		width: 100%;
	}
}
</style>
